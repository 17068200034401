import "./homepage-v2.scss";
import "../styles/shared.scss";
import * as React from "react";
import clsx from "classnames";
import { graphql, StaticQuery } from "gatsby";
import {
  TypeHomepageV2PageQueryResult,
  getPage,
  TypeHomepageV2Quote,
} from "../utils/queries/homepagev2";
import { PrismicImage, PrismicRichText } from "../utils/queries/types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Linked from "../utils/linkedItem";
import { Carousel } from "react-responsive-carousel";
import Helmet from "react-helmet";
import { AmazeeIOButton } from "../components/button/button";
import AmazeeIoMetadata from "../components/metadata";
import RichTextRender from "../utils/rich-text";
import linkResolver from "../utils/linkResolver";
import AmazeePage from "../components/layout";
import usePreviewData from "../utils/usePreviewData";
import { Resource } from "../utils/queries/resources";
import {
  StandardCard,
  HomeCustomStandardCard,
  CustomStoriesCard,
} from "../templates/resources/cards";
import { HomepageLogoCarousel } from "../components/homepage-logos/carousel";
import { PageTitle } from "../components/title/title";

const HomepageFeature = ({
  image,
  title,
  details,
}: {
  image: PrismicImage;
  title: PrismicRichText | string;
  details: PrismicRichText;
}) => {
  // @ts-ignore
  const titleRendered = title.hasOwnProperty("raw") ? (
    RichTextRender(title)
  ) : (
    <h3 className="text-2xl font-semibold">{title}</h3>
  );
  return (
    <div className="flex flex-col gap-3">
      <img src={image.url} alt={image.alt} loading="lazy" className="w-20 h-20 mx-auto" />
      <div className={`text-2xl font-semibold text-black text-center mx-auto mb-0`}>{RichTextRender(titleRendered)}</div>
      <div className="text-lg text-black w-full 2xl:w-4/5 mx-auto mb-0 font-light">{RichTextRender(details)}</div>
    </div>
  );
};

const HomepageFeature4 = ({
  image,
  title,
  details,
}: {
  image: PrismicImage;
  title: PrismicRichText | string;
  details: PrismicRichText;
}) => {
  // @ts-ignore
  const titleRendered = title.hasOwnProperty("raw") ? (
    RichTextRender(title)
  ) : (
    <h3 className="font-semibold text-2xl">{title}</h3>
  );
  return (
    <div className="col-12 mb-10 col-lg-3 home-page-feature wider">
      <div className="icon">
        <img src={image.url} alt={image.alt} loading="lazy" />
      </div>
      <div className="title">{RichTextRender(titleRendered)}</div>
      <div className="description">{RichTextRender(details)}</div>
    </div>
  );
};

export const QuoteCarouselHomePg = ({
  quotes,
  withComma = false,
}: {
  quotes: Array<TypeHomepageV2Quote>;
  withComma?: boolean;
}) => {
  return (
    <Carousel
      showArrows={true}
      showIndicators={false}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      useKeyboardArrows={true}
      autoPlay={true}
      interval={5000}
    >
      {quotes.map((quote, index) => (
        <div className="carousel-slide" key={index}>
          <div className="layover" />
          <div className="container">
            <div className="flex justify-items-center justify-content-center">
              <div className="col-12 col-lg-11 d-flex flex-column align-items-center">
                <div className="grid md:flex align-items-center mt-4">
                  <div className="quote-image">
                    {quote.image && (
                      <img
                        src={quote.image.url}
                        alt={quote.image.alt}
                        style={{ width: 210 }}
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>

                <p className="quote">"{quote.text}"</p>

                <div className="author">
                  {/* <div className="quote-author-image">
                {quote.quote_author_image && (
                    <img
                      src={ quote.quote_author_image.url }
                      alt={quote.quote_author_image.alt}
                      style={{ width: 72 }}
                      loading="lazy"
                      />
                      )}
                    </div> */}
                  <div className="name md:text-left">
                    {quote.author_name}
                    {withComma ? "," : ""}
                  </div>
                  <div className="role md:text-left">
                    {RichTextRender(quote.author_title)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

const HeroSection: React.FC = ({ data }: { data: TypeHomepageV2PageData }) => {
  // const [showVideo, setShowVideo] = React.useState(false);
  const contactUsUrl = "/product";
  const viewProductUsUrl = "/get-in-touch"

  return (
    <div className="hero-content relative">
      <div className="hero-container h-full">
        <div className="h-full items-center">
          <div className="col-content fade-in-right h-full items-center">
            <div className="flex flex-col h-full w-full justify-center content">
              <div className=" lg:w-full">
                {RichTextRender(data.hero_text)}
              </div>
              <div className="hero-subtile flex justify-center md:justify-start mt-8 mb-11">
                <div className="w-am-70 md:w-full">
                  {RichTextRender(data.hero_subtitle)}
                </div>
              </div>
              {data.hero_subtitle_secondary ? <div className="hero-secondary-subtile">
                {data.hero_subtitle_secondary}
              </div> : <></>}
              <div className="flex justify-center md:justify-start items-center">

                <Linked link_to={{ document: { data: { url: contactUsUrl } } }}>
                  <AmazeeIOButton classes="inverted inline-block px-3 py-2" skipPadding>
                    {data.cta_button_text}
                  </AmazeeIOButton>
                </Linked>
                <Linked
                  link_to={{ document: { data: { url: viewProductUsUrl } } }}
                >
                  <AmazeeIOButton classes="outline inline-block text-white px-3 py-2" skipPadding>
                    {data.cta_button_text_2}
                  </AmazeeIOButton>
                </Linked>
              </div>
              <div className="mobile-header-img">
                <img
                  src={data.hero_image_mobile.url}
                  alt={data.hero_image_mobile.alt}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          {/* <div
            className="relative header-video-graphic my-4 md:my-0 md:ml-8 md:mr-0 cursor-pointer"
            onClick={() => setShowVideo(true)}
          >
            {showVideo ? (
              <div className="iframe-wrapper">
                <iframe
                  className="media-youtube-player"
                  width="100%"
                  height="500px"
                  title="zeroOps"
                  src="https://www.youtube-nocookie.com/embed/FzPBJSwN3Pg?enablejsapi=1&autoplay=1&mute=0&controls=1&rel=0&loop=0&modestbranding&playlist=FzPBJSwN3Pg"
                  allowFullScreen={true}
                  allowscriptaccess="always"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            ) : (
              <>
                <div className="play-button-wrapper">
                  <img
                    className="play-button-passive"
                    src="/images/Video-Button-Passive.svg"
                    alt="play button passive"
                  />
                  <img
                    className="play-button-active"
                    src="/images/Video-Button-Active.svg"
                    alt="play button active"
                  />
                </div>
                <img
                  src={data.hero_video_image.url}
                  alt={data.hero_video_image.alt}
                  loading="lazy"
                />
              </>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
};

const IndexPage = ({
  data,
  location,
}: {
  data: TypeHomepageV2PageQueryResult;
  location: Location;
}) => {
  const edge = data.allPrismicHomepageV2.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(getPage(data), "prismicHomepage", true);

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );

  const renderableStoryResources = result.latest_stories.map(
    (rawResource) => new Resource(rawResource.story.document.data)
  );

  const [tabIndex, setTabIndex] = React.useState(0);
  const tabLength = result.tabs_group.length;
  const [showVideo, setShowVideo] = React.useState(false);

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="homepagev2">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
        </Helmet>
        <PageTitle title={title} />
        <AmazeeIoMetadata
          title={title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <HeroSection data={result} />

        <section id="logos" className="logos no-padding white desktop">
          <h2 className="section_header">{result.logos_section_header}</h2>
          <div className="container">
            <div className="row">
              <div className="col">
                <HomepageLogoCarousel itemsForTablet={2} logos={result.logos} />
              </div>
            </div>
          </div>
        </section>

        <section id="logos" className="logos no-padding white mobile">
          <h2 className="section_header">{result.logos_section_header}</h2>
          <div className="container">
            <div className="row">
              <div className="col">
                {result.logos.map((logomobi, index) => (
                  <div className="logo" key={index}>
                    <img
                      alt="mobile logo"
                      loading="lazy"
                      src={logomobi.logo.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* <section className="guarantees grad pb-0">
          <section
            id="tabs"
            className="tabs-header padding-bottom-small "
          >
            <div className="container mx-auto row justify-content-center">
              <div className="col-12 col-lg-10 text-center no-padding-mobile">
                <h2 className="section-title">{result.tabs_heading}</h2>
              </div>
              <div className="col-12 col-lg-7 text-center">
                <div className="subtitle">{result.tabs_subheading}</div>
              </div>
            </div>
          </section>
          <section className="guarantees-intro pb-20 pt-0">
            <div className="container">
              <div className="row flex justify-center">
                <div className="col-12 col-lg-6 text-left">
                  {result.guarantee_image && (
                    <img
                      className="w-full"
                      src={result.guarantee_image.url}
                      alt={result.guarantee_image.alt}
                      loading="lazy"
                    />
                  )}
                </div>
                <div className="col-12 col-lg-4 mt-4 lg:mt-2">
                  {RichTextRender(result.guarantee_copy)}
                </div>
              </div>
            </div>
          </section>
        </section> */}


        {/* <section id="tabs" className="tabs-section">
          <div className="text-center tabs-wrapper">
            <div className="tabs-outer tabs">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <div className="upper-controls pt-8">
                  <TabList>
                    {result.tabs_group.map((tab, index) => (
                      <Tab key={index}>
                        {tab.tab_heading}
                        <div className="line"></div>
                        <div className="arrow-down"></div>
                      </Tab>
                    ))}
                  </TabList>
                </div>
                <div className="background-dark-blue">
                  <div className="container">
                    <div className="justify-content-center">
                      {result.tabs_group.map((tab, index) => (
                        <TabPanel key={index}>
                          <h2 className="text-left tab-heading">
                            {tab.tab_heading}
                          </h2>
                          <div className="copy-holder">
                            <div className="leftside-copy">
                              {RichTextRender(tab.tab_copy_left)}
                            </div>
                            <div className="rightside-copy">
                              {RichTextRender(tab.tab_copy_right)}
                            </div>
                          </div>
                          <div className="lower-controls">
                            <TabList>
                              {result.tabs_group.map((tab, index) => (
                                <Tab
                                  key={index}
                                  selectedIndex={tabIndex}
                                  className={clsx(
                                    "react-tabs__tab",
                                    tabIndex + 1 === index
                                      ? "block next"
                                      : tabIndex - 1 === index
                                        ? "block prev"
                                        : tabIndex === tabLength - 1 &&
                                          index === 0
                                          ? "block next"
                                          : tabIndex === 0 &&
                                            index === tabLength - 1
                                            ? "block prev"
                                            : "is-hidden"
                                  )}
                                >
                                  {tab.tab_heading}
                                  <div className="line"></div>
                                  <div className="arrow-down"></div>
                                </Tab>
                              ))}
                            </TabList>
                          </div>
                        </TabPanel>
                      ))}
                    </div>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </section> */}

        <section id="features" className="features white">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="section-title mb-10 mx-auto w-full md:w-am-70 lg:w-3/5 xl:w-am-49 2xl:w-2/5">
                  {result.upper_info_blocks_heading}
                </h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
                  {result.features.map((feature, index) => (
                    <HomepageFeature
                      image={feature.image}
                      title={feature.title1.text}
                      details={feature.details}
                      key={index}
                    />
                  ))}
                </div>

                {/* <div className="flex justify-center">
                  <div
                    className="col-12 col-xl-6 px-0 relative header-video-graphic my-4 md:my-0 cursor-pointer"
                    onClick={() => setShowVideo(true)}
                  >
                    {showVideo ? (
                      <div className="iframe-wrapper">
                        <iframe
                          className="media-youtube-player"
                          width="100%"
                          height="500px"
                          title="zeroOps"
                          src="https://www.youtube-nocookie.com/embed/FzPBJSwN3Pg?enablejsapi=1&autoplay=1&mute=0&controls=1&rel=0&loop=0&modestbranding&playlist=FzPBJSwN3Pg"
                          allowFullScreen={true}
                          allowscriptaccess="always"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>
                    ) : (
                      <>
                        <div className="play-button-wrapper">
                          <img
                            className="play-button-passive"
                            src="/images/Video-Button-Passive.svg"
                            alt="play button passive"
                          />
                          <img
                            className="play-button-active"
                            src="/images/Video-Button-Active.svg"
                            alt="play button active"
                          />
                        </div>
                        <img
                          src={result.hero_video_image.url}
                          alt={result.hero_video_image.alt}
                          loading="lazy"
                        />
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section id="technologies" className="technologies no-padding medium grad">
          <div className="container">
            <h2 className="section_header mb-10 lg:w-3/4 xl:w-2/3 2xl:w-3/6 mx-auto">
              {result.technologies_heading}
            </h2>
          </div>
          <div className="slider">
            <div
              className="slide-track1 mb-3"
              style={{
                width: `${result.technologies_logos_1.length * 217}px`,
              }}
            >
              {result.technologies_logos_1.map((logo, index) => (
                <div className="slide logo bg-white m-2" key={index}>
                  <img alt={""} src={logo.logo.url} />
                </div>
              ))}
            </div>

            <div
              className="slide-track2"
              style={{ width: `${result.technologies_logos_2.length * 217}px` }}
            >
              {result.technologies_logos_2.map((logo, index) => (
                <div className="slide logo bg-white m-2" key={index}>
                  <img alt={""} src={logo.logo.url} />
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="services" className="services white">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-lg-8 mb-6 text-center">
                <h2 className="section-title">{result.headline}</h2>
                <h2 className="section-title">{result.subheadline}</h2>
              </div>
              <div className="col-12 col-lg-7 text-center">
                <p className="section-subtitle">
                  {result.info_graphic_subheading}
                </p>
              </div>
            </div>
            <div className="row services justify-center items-center">
              <div className="col-12 col-lg-7 mt-8">
                <img
                  className="desktop"
                  src={result.services_main_image_left.url}
                  alt={result.services_main_image_left.alt}
                  loading="lazy"
                />
                <img
                  className="mobile"
                  src={result.infographic_image_mobile.url}
                  alt={result.infographic_image_mobile.alt}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section id="byo-code" className="byo-code white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8 text-center">
                <h2 className="title">{result.headline1}</h2>
                <div className="subtitle">
                  {RichTextRender(result.subtitle)}
                </div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-12">
                <div className="row align-items-start text-center">
                  {result.bullets.map((feature, index) => (
                    <HomepageFeature4
                      image={feature.icon}
                      title={feature.title1}
                      details={feature.details}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section> */}


        <section id="case-studies" className="case-studies mb-20">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2 className="section-title mb-10 font-bold">
                  {result?.section_title2}
                </h2>
                <div className="buttons mb-12">
                  <a href="/resources/customer-stories">
                    <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inverted inline-block width-auto mx-1">
                      View all Customer Stories
                    </div>
                  </a>
                  <a href="https://signup.amazee.io">
                    <div className="btn-boldish amazeeio-button rounded-full px-4 py-2 btn-boldish amazeei-button inline-block width-auto mx-1">
                      Book a Demo
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <HomeCustomStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>

        {/* <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <QuoteCarouselHomePg
                  withComma={true}
                  quotes={result.quotes.map(
                    (quote) =>
                    ({
                      image: quote.image,
                      text: quote.text,
                      author_name: quote.author_name,
                      author_title: quote.author_title,
                      quote_author_image: quote.quote_author_image,
                    } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section> */}

        <section className="customer-trust grad" id="customer_trust">
          <div className="container">
            <div className="lg:w-11/12 xl:w-4/5 mx-auto">
              <h4 className="lg:w-am-90 xl:w-4/5 2xl:w-2/3 mx-auto text-black">
                Customers around the world love and trust us.
              </h4>
              <a href="/resources/our-reviews" className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 g2-badges items-center lg:w-11/12 2xl:w-3/4 mx-auto place-items-center">
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0fd7VsGrYSwRfi_Momentum-Leader-Fall-2024.svg" />
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0fcLVsGrYSwRfd_Best-Support-Fall-2024.svg" />
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0fcrVsGrYSwRfe_Easiest-to-do-Business-With-Fall-2024.svg" />
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0fdLVsGrYSwRfg_Fastest-Implementation-Fall-2024.svg" />
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0feLVsGrYSwRfk_Most-Likely-To-Recommend-Fall-2024.svg" className=" md:col-start-2 lg:col-start-5" />
                <img src="https://amazeeio.cdn.prismic.io/amazeeio/Zv0fb7VsGrYSwRfb_Best-Est-ROI-Fall-2024.svg" />
              </a>
            </div>

            <div className="mx-auto mt-11">
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                {testimonies?.map((testimony, i) => (
                  <ReviewCard key={i} data={testimony} />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="cta larger-text" id="cta">
          <div className="container">
            <div className="row">
              <div className="lg:w-9/12 xl:w-2/3 mx-auto">
                <h4 className="cta-main container col-md-12 cta-build mx-auto">
                  <span className="block w-11/12 md:w-am-92 xl:w-am-83 2xl:w-3/4 mx-auto">{result.cta}</span>
                  {/* <span className="">Build, run, and scale your applications on our</span> */}
                  {/* <span className="block text-center">open source hosting platform.</span> */}
                  <span className="block text-center">{result.subtitle1}</span>
                </h4>
                <h4>
                  <div className="container col-md-7 cta-big">

                  </div>
                </h4>
                <div className="buttons">
                  <Linked link_to={result.button_link}>
                    <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                      {result.button_text}
                    </AmazeeIOButton>
                  </Linked>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="latest-news" id="latest-news">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-md-8 d-flex justify-center flex-col items-center">
                <h4 className="leading-10">The latest from amazee.io</h4>
              </div>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {renderableStoryResources.map((resource, index) => (
                <CustomStoriesCard resource={resource} key={index} />
              ))}
            </div>
          </div>
        </section>

      </div>
    </AmazeePage>
  );
};

const IndexPageStatic: React.FC<{ location: Location }> = ({ location }) => {
  const query = graphql`
    {
      allPrismicHomepageV2 {
        edges {
          node {
            data {
              upper_info_blocks_heading
              info_graphic_subheading
              infographic_image_mobile {
                url
                alt
              }
              technologies_heading
              technologies_desktop {
                url
                alt
              }
              technologies_mobile {
                url
                alt
              }
              hero_image_mobile {
                url
                alt
              }
              hero_video_image {
                url
                alt
              }
              guarantee_image {
                url
                alt
              }
              guarantee_copy {
                html
                raw
                text
              }
              subheadline
              cta_button_text_2
              hero_subtitle_secondary
              button_text
              cta
              cta_button_text
              button_link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                    }
                  }
                }
              }
              second_button_text
              second_button_link {
                document {
                  ... on PrismicPageDefinitions {
                    id
                    data {
                      url
                      is_external
                    }
                  }
                }
              }
              quotes {
                text
                author_title {
                  html
                  raw
                  text
                }
                author_name
                quote_author_image {
                  alt
                  url
                }
                image {
                  alt
                  url
                }
              }
              logos_section_header
              headline
              headline1
              hero_bg_color
              hero_subtitle
              subtitle1
              title
              meta_title
              services_main_image_left {
                url
                alt
              }
              section_title2
              customer_stories {
                case_study {
                  document {
                    ... on PrismicResource {
                      id
                      data {
                        title
                        tags {
                          tag {
                            document {
                              ... on PrismicResourceIndustry {
                                id
                                data {
                                  industry
                                  display_in_filter_dropdown
                                }
                              }
                              ... on PrismicResourceTopic {
                                id
                                data {
                                  topic
                                  display_in_filter_dropdown
                                }
                              }
                            }
                          }
                        }
                        post_date
                        featured_on_customer_page
                        description {
                          html
                          raw
                          text
                        }
                        company_name
                        featured
                        link {
                          url
                          document {
                            ... on PrismicCaseStudy {
                              id
                              uid
                            }
                          }
                        }
                        image {
                          alt
                          url
                        }
                        resource_type {
                          document {
                            ... on PrismicResourceType {
                              id
                              data {
                                display_in_filter_dropdown
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              latest_stories {
                story {
                  document {
                    ... on PrismicResource {
                      id
                      data {
                        title
                        tags {
                          tag {
                            document {
                              ... on PrismicResourceIndustry {
                                id
                                data {
                                  industry
                                  display_in_filter_dropdown
                                }
                              }
                              ... on PrismicResourceTopic {
                                id
                                data {
                                  topic
                                  display_in_filter_dropdown
                                }
                              }
                            }
                          }
                        }
                        post_date
                        featured_on_customer_page
                        description {
                          html
                          raw
                          text
                        }
                        company_name
                        featured
                        link {
                          url
                          document {
                            ... on PrismicCaseStudy {
                              id
                              uid
                            }
                          }
                        }
                        image {
                          alt
                          url
                        }
                        resource_type {
                          document {
                            ... on PrismicResourceType {
                              id
                              data {
                                display_in_filter_dropdown
                                type
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              services {
                direction
                details {
                  text
                  raw
                  html
                }
                image {
                  url
                  dimensions {
                    height
                    width
                  }
                }
                title1
                read_more {
                  id
                  link_type
                  slug
                  type
                  document {
                    ... on PrismicPageDefinitions {
                      id
                      data {
                        url
                        is_external
                      }
                    }
                  }
                }
              }
              subtitle {
                text
                raw
                html
              }
              tabs_heading
              tabs_subheading
              tabs_group {
                tab_heading
                tab_id
                tab_copy_left {
                  text
                  raw
                  html
                }
                tab_copy_right {
                  text
                  raw
                  html
                }
              }
              logos {
                logo {
                  url
                }
              }
              technologies_logos_1 {
                logo {
                  url
                }
              }
              technologies_logos_2 {
                logo {
                  url
                }
              }
              hero_text {
                text
                raw
                html
              }
              hero_image {
                url
              }
              features {
                title1 {
                  text
                  raw
                  html
                }
                image {
                  url
                }
                details {
                  text
                  raw
                  html
                }
              }
              bullets {
                title1
                icon {
                  url
                }
                details {
                  text
                  raw
                  html
                }
              }
              page_meta_description
              page_meta_thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `;
  return (
    <StaticQuery
      render={(result: TypeHomepageV2PageQueryResult) => (
        <IndexPage location={location} data={result} />
      )}
      query={`${query}`}
    />
  );
};

const testimonies = [
  {
    star: "https://amazeeio.cdn.prismic.io/amazeeio/ZyTBwa8jQArT0HZy_Four-Point-Five-Stars.svg",
    heading: "amazee.io is ready to help you solve tough problems with one-on-one support.",
    description: `Unlike most other hosting providers, amazee.io stands out by
          providing personalized one-on-one support, even going
          so far as to schedule Zoom calls to help troubleshoot any bugs you may encounter.`,
    image: "https://images.prismic.io/amazeeio/ZyRpsK8jQArT0Gy9_Rob-Decker.jpg?auto=format,compress",
    name: "Rob Decker",
    role: "Senior Frontend Lead, Chapter Three"
  },
  {
    star: "https://amazeeio.cdn.prismic.io/amazeeio/ZyTBwK8jQArT0HZx_Five-Stars.svg",
    heading: "Fantastic service and support: Overall a great hosting partner!",
    description: `I’ve had the pleasure of working with amazee.io for seven years now. 
    Their open source hosting solution, powered by Lagoon, is fantastic to work with. 
    It’s incredibly versatile and takes the stress out of infrastructure management.`,
    image: "https://images.prismic.io/amazeeio/ZyRpr68jQArT0Gy8_Darren-Shelley.jpg?auto=format,compress",
    name: "Darren Shelley",
    role: "Head of Engineering, Add to Event"
  },
  {
    star: "https://amazeeio.cdn.prismic.io/amazeeio/ZyTBwK8jQArT0HZx_Five-Stars.svg",
    heading: "True to their slogan: “A platform built by developers, for developers.",
    description: `I always have a great experience working with amazee.io. 
    They are highly collaborative, very transparent, and proactive. 
    The communication is smooth and pleasant, making our cooperation enjoyable and efficient.`,
    image: "https://images.prismic.io/amazeeio/ZyRpsa8jQArT0Gy-_Sam-Zwaaij.jpg?auto=format,compress",
    name: "Sam Zwaaij",
    role: "Business Developer & Solution Architect, Synetic"
  }
]

const ReviewCard: React.FC<{ data: typeof testimonies[0] }> = ({ data }) => {
  return (<>
    <div className="rounded-lg border border-amazee-card-gray px-4 pt-4 pb-6 testimony-card bg-white flex flex-col justify-between gap-4">
      <div className="grid gap-4">
        <div className="stars">
          <img src={data.star} className="h-5" />
        </div>
        <h3 className="font-semibold leading-8 text-xl">
          {data.heading}
        </h3>
      </div>
      <div>
        <p className="leading-7">
          {data.description}
        </p>
      </div>

      <div className="grid gap-4">
        <hr />
        <div className="flex gap-3">
          <img src={data.image} alt={data.name}
            className="rounded-full w-14 h-14" />
          <div className="">
            <p className="font-semibold pb-0">{data.name}</p>
            <p className="pb-0">{data.role}</p>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default IndexPageStatic;
