import {
  PrismicImage,
  PrismicRichText,
  PrismicPageDefinition,
  PrismicFileLink,
} from "./types";

export type TypeHomepageV2Feature = {
  image: PrismicImage;
  title1: PrismicRichText;
  details: PrismicRichText;
};

export type TypeHomepageV2Quote = {
  text: string;
  author_name: string;
  author_title: PrismicRichText;
  quote_author_image: PrismicImage;
  image?: PrismicImage;
};

export type TypeHomepageV2Service = {
  image: PrismicImage;
  icon: PrismicImage;
  title1: string;
  details: PrismicRichText;
  read_more: PrismicPageDefinition;
  direction: boolean; // True == right, False == left
};

export type TypeHomepageTabs = {
  tab_heading: string;
  tab_id: string;
  tab_copy_left: PrismicRichText;
  tab_copy_right: PrismicRichText;
};

export type TypeHomepageV2Bullet = {
  icon: PrismicImage;
  title1: string;
  details: PrismicRichText;
};

export type TypeHomepageV2Logo = {
  logo: PrismicImage;
};

export type TypeHomepageV2PageData = {
  video: PrismicFileLink;
  title: string;
  meta_title: string;
  subtitle1: string;
  subtitle: PrismicRichText;
  hero_text: PrismicRichText;
  hero_bg_color: string;
  headline1: string;
  headline: string;
  cta: string;
  logos_section_header: string;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;
  button_text: string;
  button_link: PrismicPageDefinition;
  second_button_text: string;
  second_button_link: PrismicPageDefinition;
  bullets: Array<TypeHomepageV2Bullet>;
  services: Array<TypeHomepageV2Service>;
  services_main_image_left: PrismicImage;
  guarantee_image: PrismicImage;
  guarantee_copy: PrismicRichText;
  logos: Array<TypeHomepageV2Logo>;
  technologies_logos_1: Array<TypeHomepageV2Logo>;
  technologies_logos_2: Array<TypeHomepageV2Logo>;
  features: Array<TypeHomepageV2Feature>;

  upper_info_blocks_heading: string;
  subheadline: string;

  info_graphic_subheading: string;
  infographic_image_mobile: PrismicImage;
  technologies_heading: string;
  technologies_desktop: PrismicImage;
  technologies_mobile: PrismicImage;
  cta_button_text_2: string;
  hero_subtitle_secondary: string;
  hero_image_mobile: PrismicImage;

  tabs_heading: string;
  tabs_subheading: string;
  tabs_group: Array<TypeHomepageTabs>;
};

export type TypeHomepageV2PageQueryResult = {
  allPrismicHomepageV2: {
    edges: Array<{
      node: { data: TypeHomepageV2PageData };
    }>;
  };
};

export const getPage = (
  data: TypeHomepageV2PageQueryResult
): TypeHomepageV2PageData => {
  return data.allPrismicHomepageV2.edges[0].node.data;
};
