import React from "react";
import Carousel from "react-multi-carousel";
import { TypeHomepageV2Logo } from "../../utils/queries/homepagev2";

export const HomepageLogoCarousel = ({
  logos,
  itemsForMobile = 2,
  itemsForTablet = 3,
  itemsForBigTablet = 4,
  itemsForDesktop = 7,
  grayscale = false,
}: {
  itemsForMobile?: number;
  itemsForTablet?: number;
  itemsForBigTablet?: number;
  itemsForDesktop?: number;
  logos: Array<TypeHomepageV2Logo>;
  grayscale?: boolean;
}) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 30000, min: 992 },
      items: itemsForDesktop,
      slidesToSlide: 1,
    },
    bigTablet: {
      breakpoint: { max: 992, min: 768 },
      items: itemsForBigTablet,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 384 },
      items: itemsForTablet,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 384, min: 0 },
      items: itemsForMobile,
      slidesToSlide: 1,
    },
  };

  const renderedLogos = logos.map((logo, index) => (
    <div className="logo" key={index}>
      <img
        loading="lazy"
        src={logo.logo.url}
        style={{
          filter: grayscale ? "grayscale(1)" : "none",
        }}
      />
    </div>
  ));

  return (
    <Carousel
      ssr={true}
      responsive={responsive}
      showDots={false}
      arrows={false}
      autoPlay={false}
      autoPlaySpeed={4000}
      infinite
    >
      {renderedLogos}
    </Carousel>
  );
};
